<template>
    <v-container fluid>
      <v-toolbar dense color="transparent" flat>
        <v-toolbar-title class="grey--text text--darken-2">
          <v-subheader>
            Gerencia <v-icon>mdi-chevron-right</v-icon> POS Ventas
          </v-subheader>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="openDrawer()">
          <v-icon>mdi-filter-cog</v-icon>
        </v-btn>
        <!-- <v-btn v-print="'#bookingTable'" icon>
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <download-excel :name="'Ocupacion'" :before-generate="beforeDownload" :data="dataExcel">
          <v-btn icon>
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </download-excel> -->
      </v-toolbar>
      <v-toolbar dense flat color="transparent">
        <v-icon left color="primary">mdi-finance</v-icon>
        <v-toolbar-title class="primary--text">POS</v-toolbar-title>
        <v-divider class="primary mx-7"></v-divider>
        <v-icon left color="primary">mdi-finance</v-icon>
        <v-toolbar-title class="primary--text">POS</v-toolbar-title>
      </v-toolbar>
      <div class="text-center primary--text"><span>{{filters.rangeDate[0]}} - {{filters.rangeDate[1]}}</span></div>
      
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="align-content-end">
            <SalesByBusinessChart :business="accumulateByBusiness" ref="salesByBusinessChart"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="align-content-end d-flex align-center justify-center">
            <RoomServiceChart :countRoomService="countRoomService" ref="ordersChartClient"/>
          </v-col>
          <v-col cols="3" class="align-content-end d-flex align-center justify-center">
            <ClientChart :acumClientInternal="acumClientInternal" :acumClientExternal="acumClientExternal" ref="ordersChartClient"/>
          </v-col>
          <v-col cols="3" class="align-content-end d-flex align-center justify-center">
            <PosChart :acumDesktop="acumDesktop" :acumApp="acumApp" ref="ordersChart"/>
          </v-col>
          <v-col cols="3" class="align-content-end">
            <PayChart :filters="filters" ref="payChart"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <OrderTable :filters="filters" ref="OrderTable"/>
          </v-col>
          <v-col cols="3">
            <MomentDayChart :filters="filters" ref="MomentDayChart"/>
          </v-col>
          <v-col cols="5">
            <PosNumberCards :totalTip="totalTip" :totalSales="totalSales" :countOrders="countOrders" :averageOrders="averageOrders" :discountOrders="discountOrders" :realIncome="realIncome" ref="PosNumberCards"/>
          </v-col>
        </v-row>
      </v-container>
      <!-- Filters Menu -->
      <v-navigation-drawer right v-model="drawerRight" fixed temporary width="450" app>
        <v-toolbar dark class="elevation-0 secondary">
          <v-toolbar-title> <strong>Filtros y busquedas</strong></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-filter-cog</v-icon>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col class="pb-0">
              <v-subheader class="secondary--text" style="height: auto;">
                Rango de fecha
              </v-subheader>
              <v-dialog ref="modalDateStart" v-model="modalDateStart" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :loading="loading" prepend-icon="mdi-calendar" v-model="filters.rangeDate" readonly v-bind="attrs" v-on="on" hide-details dense clearable :placeholder="`26/09/2024`"></v-text-field>
                </template>
                <v-date-picker range v-model="filters.rangeDate" @input="handleDateInput"></v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-footer fixed padless>
            <v-row>
              <v-col class="text-center" cols="12" >
                <v-toolbar  class="elevation-0" color="grey lighten-5">
                  <v-btn @click="getReportData" class="elevation-0 primary--text" text>Aplicar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0" text @click="clean">Limpiar</v-btn>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-footer>
        </v-container>
      </v-navigation-drawer>
    </v-container>
  </template>
  
  <script>
  import moment from 'moment'
  import apiOrder from "@/api/externos/orderPos";
  import OrderTable from '@/components/gerencia/pos/OrderTable'
  import MomentDayChart from '@/components/gerencia/pos/MomentDayChart.vue'
  import PosNumberCards from '@/components/gerencia/pos/OrderNumberCards.vue'
  import PosChart from '@/components/gerencia/pos/OrdersChart.vue'
  import ClientChart from '@/components/gerencia/pos/OrdersChartClients.vue'
  import RoomServiceChart from '@/components/gerencia/pos/OrdersChartRoomService.vue'
  import PayChart from '@/components/gerencia/pos/SalesByPayChart.vue'
  import SalesByBusinessChart from '@/components/gerencia/pos/SalesByBusinessChart.vue'

  export default {
    components: { OrderTable, SalesByBusinessChart, MomentDayChart,  PosNumberCards, PosChart, PayChart, ClientChart, RoomServiceChart  },
    data: () => ({
      dataExcel: [],
      drawerRight: false,
      loading: false,
      modalDateStart: false,
      modalDateEnd: false,
      modalCreatedAt: false,
      filters: {
        rangeDate: [moment.utc().startOf('month').format('YYYY-MM-DD'), moment.utc().endOf('month').format('YYYY-MM-DD')], 
        types: [
          { text: 'Sí', value: true },
          { text: 'No', value: false },
        ]
      },
      totalTip: 0,
      totalSales: 0,
      countOrders: 0,
      averageOrders: 0,
      discountOrders: 0,
      realIncome: 0,
      acumApp: 0,
      acumDesktop: 0,
      accumulateByBusiness:{},
      acumClientInternal: 0,
      acumClientExternal: 0,
      countRoomService: {
        isRoomService: 0,
        noRoomService: 0
      }
    }),
    mounted() {
      this.getDataMetrics()
      this.getDataClients()
      this.getDataRoomService()
    },
    methods: {
      handleDateInput(dates) {
      if (dates && dates.length === 2) this.modalDateStart = false
      },
      beforeDownload() {
        this.dataExcel = this.$refs.bookingTable.items
      },
      openDrawer() {
        this.drawerRight = true
      },
      async getReportData() {
        await this.getDataMetrics()
        await this.getDataClients()
        await this.getDataRoomService()
        this.$refs.OrderTable.getDataTable(this.filters)
        this.$refs.salesByBusinessChart.getData(this.filters)
        this.$refs.payChart.getDataMetrics()
        this.$refs.MomentDayChart.getDataMetrics()
      },


      async getDataMetrics() {
        try {
          let dates = {}
          this.loading = true
          if (this.filters.rangeDate.length > 1)
            dates = { initDate: this.filters.rangeDate[0], endDate: this.filters.rangeDate[1] }
          else if (this.filters.rangeDate.length == 1)
            dates = {
              initDate: this.filters.rangeDate[0],
              endDate: this.filters.rangeDate[0],
            }
          const query = {
            initDate: dates.initDate,
            endDate: dates.endDate
          }
          const {data: {data:dataOrder} } = await apiOrder.getSalesGeneral(query)
          this.totalTip = dataOrder.acumTip
          this.acumApp = dataOrder.acumApp
          this.acumDesktop = dataOrder.acumDesktop
          this.totalSales = dataOrder.acumApp + dataOrder.acumDesktop + dataOrder.acumDiscount
          this.countOrders = dataOrder.countApp + dataOrder.countDesktop
          this.averageOrders = this.totalSales / this.countOrders
          this.discountOrders = dataOrder.acumDiscount
          this.realIncome =  this.totalSales - dataOrder.acumDiscount - dataOrder.acumTip
          this.accumulateByBusiness = dataOrder.accumulateByBusiness
          this.loading = false
        } catch (error) {
          console.log(error, "error")
        }
      },
      async getDataClients() {
        try {
          let dates = {}
          this.loading = true
          if (this.filters.rangeDate.length > 1)
            dates = { initDate: this.filters.rangeDate[0], endDate: this.filters.rangeDate[1] }
          else if (this.filters.rangeDate.length == 1)
            dates = {
              initDate: this.filters.rangeDate[0],
              endDate: this.filters.rangeDate[0],
            }
          const query = {
            initDate: dates.initDate,
            endDate: dates.endDate
          }
          const {data: {data:dataOrder} } = await apiOrder.getClientInternalAndExternal(query)
          this.acumClientExternal = dataOrder.acumClientExternal
          this.acumClientInternal = dataOrder.acumClientInternal
          this.loading = false
        } catch (error) {
          console.log(error, "error")
        }
     },
     async getDataRoomService() {
        try {
          let dates = {}
          this.loading = true
          if (this.filters.rangeDate.length > 1)
            dates = { initDate: this.filters.rangeDate[0], endDate: this.filters.rangeDate[1] }
          else if (this.filters.rangeDate.length == 1)
            dates = {
              initDate: this.filters.rangeDate[0],
              endDate: this.filters.rangeDate[0],
            }
          const query = {
            initDate: dates.initDate,
            endDate: dates.endDate
          }
          const {data: {data:dataOrder} } = await apiOrder.getOrdersRoomService(query)
          this.countRoomService.isRoomService = dataOrder.roomService
          this.countRoomService.noRoomService = dataOrder.noRoomService
          this.loading = false
        } catch (error) {
          console.log(error, "error")
        }
     },
      clean() {
        this.filters.rangeDate = []
      }
    },
    watch: {
    },
  }
  </script>