<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          General <v-icon>mdi-chevron-right</v-icon> Reporte de reservas
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
      <v-btn v-print="'#bookingTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <download-excel :name="'Ocupacion'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <BookingTable ref="bookingTable"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BookingTable from '@/components/reports/booking/BookingTable'
export default {
  components: { BookingTable },
  data: () => ({
    dataExcel: [],
  }),
  methods: {
    formatDataTable(item) {
      const data = {
        REF: item.ref,
        CONTACTO: item.wayContactName,
        CHECKIN: item.dateStart,
        CHECKOUT: item.dateEnd,
        CLIENTE: item?.user?.name ? `${item.user.name} ${item.user.lastName}` : '',
        TIPOLOGIA: item?.place?.typology?.name ? item?.place?.typology?.name : '',
        HABITACION: item?.place?.name ? item?.place?.name : '',
        ESTADO: item.status,
        CREADA: item.createdAt,
        TOTAL: item.total,
      }
      return data
    },
    async beforeDownload() {
      const items = await this.$refs.bookingTable.getBookings(true)
      console.log('items', items)
      const aux = JSON.parse(JSON.stringify(items))
      console.log('this.$refs.bookingTable.items', this.$refs.bookingTable.items)
      this.dataExcel = aux.map(item => this.formatDataTable(item))
      console.log('this.dataExcel', this.dataExcel)
    },
    openDrawer() {
      this.$refs.bookingTable.openDrawer()
    },
  },
}
</script>