
<template>
<div class="text-center">
  <v-card class="elevation-0 text-center" style="height: 100%" max-height="300px">
    <h6>Por Servicio a la Habitación</h6>
      <Pie
        :chart-options="chartOptions"
        :data="chartData"
      />
  </v-card>
    <h5 class="pt-3">Número de Ordenes</h5>
</div>
</template>
  
<script>
import { Line as LineChartGenerator, Pie } from 'vue-chartjs'
import {
Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, PointElement, LinearScale, ArcElement } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, CategoryScale, PointElement, LinearScale, LineElement, ArcElement)
  
  export default {
    components: { LineChartGenerator, Pie },
    props: {
      styles: {
      type: Object,
      default: () => {}
    },
    countRoomService: { type: Object, default: {
        isRoomService: 0,
        noRoomService: 0
    }},
    },
    data: () => ({
      loading: true,
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }),
    mounted() {
      this.getData()
    },
    watch: {
      countRoomService: {
      handler () {
        this.getData()
      },
      deep: true,
    },
    },
    methods: {
      getData() {
        const total = this.countRoomService.isRoomService + this.countRoomService.noRoomService
        this.chartData = {
          labels: [ `NO ${total > 0 ? ((this.countRoomService.noRoomService * 100) / total).toFixed(2) : 0}%`, `SÍ ${total > 0 ? ((this.countRoomService.isRoomService * 100) / total).toFixed(2) : 0}%`],
          datasets: [{
            backgroundColor: ['#877862', '#53514f'],
            data: [ this.countRoomService.noRoomService, this.countRoomService.isRoomService, ]
          }]
        }
      }
    },
  }
  </script>