import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/DashboardView.vue'
import OcupacionView from '../views/booking/OcupacionView.vue'
import BookingView from '@/views/booking/BookingsView.vue'
import SeguimientosView from '@/views/booking/SeguimientosView.vue'
import RecaudoView from '@/views/gerencial/RecaudoView.vue'
import PosStatisticsView from '@/views/gerencial/PosStatisticsView.vue'
import ActiveBalances from '@/views/gerencial/ActiveBalances.vue'
import InventoryView from '@/views/gerencial/InventoryView.vue'
import ReviewView from '@/views/review/ReviewView.vue'
import TaskView from '@/views/housekeeping/TaskView.vue'
import EventView from '@/views/event/EventView.vue'
import ServiceOrderView from '@/views/services/OrderView.vue'
import UseTimeView from '@/views/useTime/UseTimeView.vue'
import TipView from '@/views/employee/tip/TipView.vue'
import TaskDurationView from '@/views/employee/task/TaskDurationView.vue'
import SalesView from '@/views/booking/SalesView.vue'
import ComercialView from '@/views/booking/ComercialView.vue'
import ForecastView from '@/views/booking/ForecastView.vue'
// import ActiveBalances from '@/views/booking/ActiveBalances.vue'
import OrderView from '@/views/accounting/OrderView.vue'
import PayView from '@/views/accounting/PayView.vue'
import OrderPos from '@/views/pos/Sales.vue'
import OrderPosGroup from '@/views/pos/SalesGroup.vue'
import OrderPosPopularity from '@/views/pos/Popularity.vue'
import OrderPosGift from '@/views/pos/Gift.vue'
import OrderPosDetail from '@/views/pos/DetailSales.vue'
import OrderPosPay from '@/views/pos/PayOrder.vue'
import OrderPosSaleApp from '@/views/pos/SalesApp.vue'
import OrderPosStatus from '@/views/pos/StatusTime.vue'
import ClientList from '@/views/client/ClientList.vue'
import Login from '@/views/auth/Login.vue'
import EventTicket from '@/views/event/qr/EventTicket.vue'
import PlaceReservation from '@/views/place/placeReservation/PlaceReservation.vue'
import Leads from '@/views/booking/Leads.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: HomeView,
  },
  // General links
  {
    path: '/general/ocupacion',
    name: 'ocupacion',
    component: OcupacionView,
  },
  {
    path: '/general/booking',
    name: 'reservas',
    component: BookingView,
  },
  {
    path: '/general/sales',
    name: 'sales',
    component: SalesView,
  },
  {
    path: '/general/comercial',
    name: 'comercial',
    component: ComercialView,
  },
  {
    path: '/general/forecast',
    name: 'forecast',
    component: ForecastView,
  },
  {
    path: '/general/leads',
    name: 'Leads',
    component: Leads,
  },
  // {
  //   path: '/general/seguimiento',
  //   name: 'seguimiento',
  //   component: SeguimientosView,
  // },
  {
    path: '/general/balance',
    name: 'balances',
    component: ActiveBalances,
  },
  {
    path: '/gerencia/recaudo',
    name: 'recaudo',
    component: RecaudoView,
  },
  {
    path: '/gerencia/pos',
    name: 'pos',
    component: PosStatisticsView,
  },
  {
    path: '/gerencia/performance',
    name: 'inventory',
    component: InventoryView,
  },
  {
    path: '/accounting/order',
    name: 'order',
    component: OrderView,
  },
  {
    path: '/accounting/pay',
    name: 'pay',
    component: PayView,
  },
  // Auth
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/pos/sales',
    name: 'pos-sales',
    component: OrderPos,
  },
  {
    path: '/pos/sales-group',
    name: 'pos-sales-group',
    component: OrderPosGroup,
  },
  {
    path: '/pos/popularity',
    name: 'pos-popularity',
    component: OrderPosPopularity,
  },
  {
    path: '/pos/gift',
    name: 'pos-gift',
    component: OrderPosGift,
  },
  {
    path: '/pos/details',
    name: 'pos-details',
    component: OrderPosDetail,
  },
  {
    path: '/pos/payment',
    name: 'pos-payment',
    component: OrderPosPay,
  },
  {
    path: '/pos/status',
    name: 'pos-status',
    component: OrderPosStatus,
  },
  {
    path: '/hk/task',
    name: 'task',
    component: TaskView,
  },
  {
    path: '/event/calendar',
    name: 'event',
    component: EventView,
  },
  {
    path: '/service/order',
    name: 'service',
    component: ServiceOrderView,
  },
  {
    path: '/useTime',
    name: 'useTime',
    component: UseTimeView,
  },
  {
    path: '/app/sale',
    name: 'sale-app',
    component: OrderPosSaleApp,
  },
  {
    path: '/employee/tip',
    name: 'tip',
    component: TipView,
  },
  {
    path: '/employee/tasks',
    name: 'task-duration',
    component: TaskDurationView,
  },
  {
    path: '/client/list',
    name: 'clientlist',
    component: ClientList,
  },
  {
    path: '/review/:type',
    name: 'review',
    component: ReviewView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/eventTicket/movements',
    name: 'eventTicket',
    component: EventTicket,
  },
  {
    path: '/placeReservation/movements',
    name: 'placeReservation',
    component: PlaceReservation,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
