<template>
  <v-container fluid>
    <v-toolbar dense color="transparent" flat>
      <v-toolbar-title class="grey--text text--darken-2">
        <v-subheader>
          POS <v-icon>mdi-chevron-right</v-icon> KPI POS
        </v-subheader>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="openDrawer()">
        <v-icon>mdi-filter-cog</v-icon>
      </v-btn>
      <!-- <v-btn v-print="'#posTable'" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn> -->
      <download-excel :name="'POS-SALES'" :before-generate="beforeDownload" :data="dataExcel">
        <v-btn icon>
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </download-excel>
    </v-toolbar>
    <v-row>
      <v-col>
        <OrderTable ref="posTable"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderTable from '@/components/reports/pos/StatusTable'
export default {
  components: { OrderTable },
  data: () => ({
    dataExcel: [],
  }),
  methods: {
    beforeDownload() {
      this.dataExcel = this.mapOrders(this.$refs.posTable.dataOrder)
    },
    openDrawer() {
      this.$refs.posTable.openDrawer()
    },
    mapOrders(data) {
      const body = data.map(e=>{
        return {
          "Negocio": e.business.name,
          "N° orden": e.invoice,
          "En preparación": e.time1.text,
          "Listo para entregar": e.time2.text,
          "Cerrado": e.time3.text,
          "Abierta - Cerrado": e.time4.text,
        }
      })
      return body
    }
  },
}
</script>