
<template>
<div class="text-center">
  <v-card class="elevation-0 text-center" style="height: 100%" max-height="300px">
    <h6>Por Clientes</h6>
      <Pie
        :chart-options="chartOptions"
        :data="chartData"
      />
  </v-card>
    <h5 class="pt-3">Ventas por tipo de cliente</h5>
</div>
</template>
  
<script>
import { Line as LineChartGenerator, Pie } from 'vue-chartjs'
import {
Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, PointElement, LinearScale, ArcElement } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, CategoryScale, PointElement, LinearScale, LineElement, ArcElement)
  
  export default {
    components: { LineChartGenerator, Pie },
    props: {
      styles: {
      type: Object,
      default: () => {}
    },
    acumClientExternal: { type: Number, default: 0 },
    acumClientInternal: { type: Number, default: 0 },
    },
    data: () => ({
      loading: true,
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }),
    mounted() {
      this.getData()
    },
    watch: {
      acumClientExternal() {
        this.getData()
      },
      acumClientInternal() {
        this.getData()
      }
    },
    methods: {
      getData() {
        const total = this.acumClientInternal + this.acumClientExternal
        this.chartData = {
          labels: [`Ventas Internas ${total > 0 ? ((this.acumClientInternal * 100) / total).toFixed(2) : 0}%`, `Ventas Externas ${total > 0 ? ((this.acumClientExternal * 100) / total).toFixed(2) : 0}%`],
          datasets: [{
            backgroundColor: ['#503a1a', '#a98a5e'],
            data: [this.acumClientInternal, this.acumClientExternal ]
          }]
        }
      }
    },
  }
  </script>